const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '63bd383b82622d4660d0fc8f',
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    disabledComponents: ['wTagLikeCount', 'wCardsEngagementLabels'],
    metadatas: {
      name: `Radio Petit Forestier`,
      colorPrimary: '#00ae42',
      colorAccent: '#52423c',
      supportEmailAddress: 'support@radiopetitforestier.fr',
    },
    analytics: {
      matomo: '32',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'solo',
        contentId: '63d3bf2ecb91d7e6af483fde',
      },
    ],
    WFeedBack: {
      // ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
      firstFeedback: {
        isRecorder: true,
        maxDuration: 30,
        headCaption: `Une envie de faire une dédicace ? Enregistre ton message vocal !`,
        pseudonym: {
          label: 'Nom ou pseudo',
          placeholder: 'Martin',
          required: true,
        },
        recorderForm: [
          {
            label: 'Ville de votre Agence',
            placeholder: 'Angers',
            value: '',
            type: 'text-field',
            required: false,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '01 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: false,
          },
          {
            label:
              "J'accepte que mon message soit diffusé sur Radio Petit Forestier",
            value: false, // besoin d'une valeur par défault (true/false)
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
    enableBlocks: true,
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
