var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.$route.name === 'index')?_c('v-container',{staticClass:"pa-2"},[_c('v-card',{attrs:{"color":"#E8F5E9"}},[_c('v-card-text',{staticClass:"w-block__body2",class:{
          'pa-2': _vm.$voicer.isMobile === false,
          'pa-1': _vm.$voicer.isMobile === true,
          Subtitle2: _vm.$voicer.isMobile,
          Subtitle2: _vm.$voicer.isMobile === false,
        }},[_c('strong',[_vm._v("\n          Salut les verts ! Bienvenue sur VOTRE radio 100 % Petit Forestier\n          !")]),_vm._v(" "),_c('br'),_vm._v("\n        Vous souhaitez laisser une dédicace, passer un message à un collègue\n        ou tout simplement laisser un avis sur la radio ?\n        "),_c('br'),_vm._v("\n        C'est possible en appelant au\n        "),_c('a',{attrs:{"href":"tel:+33173608786"}},[_vm._v("01 86 86 77 77")]),_vm._v("\n        , par mail sur\n        "),_c('a',{attrs:{"href":"mailto:contact@radiopetitforestier.fr"}},[_vm._v("contact@radiopetitforestier.fr")]),_vm._v("\n        ou en cliquant sur le bouton ci-dessous !\n      ")])],1),_vm._v(" "),_c('v-sheet',{staticClass:"message_welcome_sir text-alternate",class:{
        'pl-3 pr-3 pt-2': _vm.$voicer.isMobile,
        'pb-2': _vm.$voicer.isMobile && !_vm.$slots['cta'],
        'pa-4': _vm.$voicer.isDesktopOrTablet,
        headline: _vm.$voicer.isDesktopOrTablet,
        'body-2': _vm.$voicer.isMobile,
      }},[_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.showFeedbackModal = true}}},[_vm._v("\n          Enregistre ton message !\n        ")]),_vm._v(" "),_c('w-feedback-popup',{attrs:{"id":"firstFeedback"},model:{value:(_vm.showFeedbackModal),callback:function ($$v) {_vm.showFeedbackModal=$$v},expression:"showFeedbackModal"}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }