<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-card color="#E8F5E9">
        <v-card-text
          class="w-block__body2"
          :class="{
            'pa-2': $voicer.isMobile === false,
            'pa-1': $voicer.isMobile === true,
            Subtitle2: $voicer.isMobile,
            Subtitle2: $voicer.isMobile === false,
          }"
        >
          <strong>
            Salut les verts ! Bienvenue sur VOTRE radio 100 % Petit Forestier
            !</strong
          >
          <br />
          Vous souhaitez laisser une dédicace, passer un message à un collègue
          ou tout simplement laisser un avis sur la radio ?
          <br />
          C'est possible en appelant au
          <a href="tel:+33173608786">01 86 86 77 77</a>
          , par mail sur
          <a href="mailto:contact@radiopetitforestier.fr"
            >contact@radiopetitforestier.fr</a
          >
          ou en cliquant sur le bouton ci-dessous !
        </v-card-text>
      </v-card>
      <v-sheet
        class="message_welcome_sir text-alternate"
        :class="{
          'pl-3 pr-3 pt-2': $voicer.isMobile,
          'pb-2': $voicer.isMobile && !$slots['cta'],
          'pa-4': $voicer.isDesktopOrTablet,
          headline: $voicer.isDesktopOrTablet,
          'body-2': $voicer.isMobile,
        }"
      >
        <div>
          <v-btn color="primary" @click.stop="showFeedbackModal = true">
            Enregistre ton message !
          </v-btn>
          <w-feedback-popup id="firstFeedback" v-model="showFeedbackModal" />
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-sheet {
  border-radius: 8px;
}
.v-card {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}
</style>
