/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'PetitForestier',
        clientId: 'vi-petit-forestier',
        buttonText: 'Connexion Petit Forestier',
        autoLogin: true,
      },
    ],
  ],
}
