/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'solo',
      contentId: '63bd6d1363a9e93a34b62173',
    },
  ],
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'PetitForestier',
        clientId: 'vi-petit-forestier-staging',
        buttonText: 'Connexion Petit Forestier',
        autoLogin: true,
      },
    ],
  ],
}
