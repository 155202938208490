const colors = require('vuetify/es5/util/colors').default
const { hexToRgb } = require('./utils/utils')

const config = {
  /**
   * @type {String}
   * Brocoli Site ID, required
   */
  siteId: process.env.BROCOLI_SITE_ID,

  /**
   * @type {Object}
   * app properties, overridable via config or brocoli
   * site metadatas (slug is the property)
   */
  metadatas: {
    /**
     * @type {String}
     * the application name
     */
    name: `Bubblecast`,

    /**
     * @type {String}
     * rgba or hexadecimal value used as backgroundColor by the
     * global app loader
     * if undefined (default), bubblecast will use the `colorPrimary`
     * value
     */
    loadingColor: undefined,
    /**
     * @type {String}
     * rgba or hexadecimal value used as backgroundColor by the
     * screen loader (in webapp mode)
     * if undefined (default), bubblecast will use the `colorPrimary`
     * value
     */
    webappThemeColor: undefined,
    /**
     * @type {String}
     * the manifest lang property (default: fr-FR)
     */
    lang: 'fr-FR',
    /**
     * @type {String}
     * the head meta charset property (default: utf-8)
     */
    charset: 'utf-8',
    /**
     * @type {String}
     * the theme.vuetify.primary color, if defined here
     * will override the theme.vuetify.primary color
     */
    colorPrimary: '#e84b23',
    /**
     * @type {String}
     * the theme.vuetify.accent color, if defined here
     * will override the theme.vuetify.accent color
     */
    colorAccent: '#ededed',
    colorSecondary: colors.amber.darken3,
    colorInfo: colors.teal.lighten1,
    colorWarning: colors.amber.base,
    colorError: colors.deepOrange.accent4,
    colorSuccess: colors.green.accent4,

    /**
     * @type {String}
     * Name of application logo
     * should be located in /assets or /statics
     */
    iconFileName: 'icon.png',
    iconProperties: {},

    /**
     * @type {Boolean}
     * If true, titles & subtitles will be rendered
     * with the defined font-alts
     * @see theme.fonts section
     */
    useFontAlt: true,

    /**
     * @type {String}
     * Technical support email address used if
     * user can't access properly to the webapp
     */
    supportEmailAddress: 'support@bubblecast.co',
  },

  analytics: {
    /**
     * @type {string}
     * Id of the google analytics app
     */
    ga: process.env.GA_ID || undefined,

    /**
     * @type {boolean}
     * Active the User ID google analytics feature
     * If you wan't this feature, you must have customer
     * privacy agreement and explicitely activate this
     * feature on GA
     * See documentation about user id here:
     * https://support.google.com/analytics/answer/3123666
     */
    gaFeatureUid: true,

    /**
     * @type {string}
     * Matomo token
     * see https://mto.mediameeting.tech/
     */
    matomo: process.env.MTO_TOKEN || undefined,

    /**
     * @type {string}
     * Token to track events on mix panel
     */
    mixpanel: process.env.MIXPANEL_TOKEN || undefined,
  },

  /**
   * @type {Array<String>}
   * @type {Array<Array[name: string, options: object]>}
   * List of the auth modes that can be used
   * to authenticate for this site
   * Supported types are : 'local', 'providers'
   * If local is passed, you can override default options as:
   *
   * @example
   * ```
   * authMode: [
   *   ['local', {
   *     // @default 'default'
   *     // @param 'default' : default login (username / password)
   *     // @param 'unique_password' : shared account (user set only a generic password)
   *     // @param 'otp' : one time password (a short token is sent by mail / sms)
   *     // @type {string}
   *     // if mode = 'unique_password' you must indicate the `defaultLogin` property
   *     mode: 'default'
   *   }]
   * ]
   * ```
   */
  authMode: [],

  /**
   * @type {Boolean}
   * if `true` a checkbox _should_ be checked
   * to perform a `login action`
   * this checkbox force user to aprove legals
   * to perform the connection
   */
  authOptInRequired: false,

  /**
   * @type {Boolean}
   * if `true` will override the `site.optRegistrable` property
   * to render the connection form
   * usefull in development mode
   * if this option is `true` in a production mode, an error
   * will be thrown
   */
  authForceLocaleForDevelopment: false,

  /**
   * @type {Array<String|Regex>}
   * perform a regex check of the login entry in locale
   * registration / connection
   * can be : phone, email or a personal regex
   * @example
   * ['email', 'phone', /^[a-zA-Z0-9_]*$/]
   */
  authLocaleLoginFormat: ['email'],

  /**
   * @type {Array[Array<Provider, Options>]}
   * list of supported providers on the current application
   * currently supported providers :
   *  _ WSSOKeycloak
   *  _ custom sso providers
   *
   * @usage
   * [String: provider, Object: options]
   * @example
   * [
   *   // you can use your own wssocomponent (created in the WSSO folder)
   *   // as below :
   *   ['WSSOExample', {
   *     // options
   *   }]
   * ]
   *
   * _google provider options_
   * @param {Object} options
   * @param {Object} options.signInOptions
   * @param {Object} options.signInParams
   */
  authOAuthProviders: [],

  /**
   * @type {Array<Object>}
   * list of special routes (availables in the topbar / bottombar)
   * @param {String} title
   * @param {String} slug
   * @param {String} icon
   * @param {Object} options
   * @param {Object} options.filter : boolean or array of tag slugs
   * @param {Boolean} options.highlighted
   * @param {Boolean} options.visibleInMenu
   * @param {Object} options.payload
   * @param {String} options.payload.is
   * @param {Function} options.reducer
   * ```js
   * function reducer (contents, storeContext) {
   *   // where `contents` is a spoke `PseudoArray`
   *   // and `storeContext` presents the contents light store
   *   // contains `state`, `getters` and `rootState`
   *   return contents.toArray()
   * }
   * ```
   * @param {Object} router
   * @param {String} router.path
   * @param {String} router.mode
   *
   * @documentation
   * Override the `pages` configuration with `voicerconfig/page`
   *
   * @example
   * pages: (pager) => {
   *   return pager
   *    .remove('radios')
   *    .add({
   *      title: 'Lives',
   *      name: 'streams',
   *      icon: 'live-tv',
   *      options: {},
   *      router: {}
   *    })
   *    .update('home', (page) => {
   *      page.setReducer((content) => {
   *        return content.toArray().filter(content => content.slug === 'home')
   *      })
   *    })
   * }
   */
  basePages: [
    {
      title: 'Accueil',
      name: 'index',
      icon: 'home',
      router: {
        path: '/',
      },
    },
  ],

  /**
   * @type {Boolean}
   * if `true`, the player can't be closed
   */
  closablePageListen: false,

  /**
   * @type {Boolean}
   * if `true`, player is not filled with
   * the last available content
   */
  disableAutosetPlayerContent: false,

  /**
   * @type {Boolean}
   * if true, player will not autoplay content
   */
  disableAutoplay: false,

  /**
   * @type {Boolean}
   * if `true`, the navigation items are not
   * defined as `active` based on route.mode
   * but use native router path algorithm
   */
  disableRouterActivelinkComputationBasedOnMode: false,

  /**
   * @type {Boolean}
   * if `true`, cards are not clickable and user
   * cannot access to the listen page
   */
  disablePageListen: false,

  /**
   * @type {String}
   * Add a `yyyy-mm` (month is padded to 2) string here to allow datepicker
   * selection from this entry time
   * @example
   * "2019-04"
   */
  datePickerStartAt: null,

  /**
   * @type {Array<String>}
   * list of disabled components
   * currently supported:
   * - wAuthenticatorPasswordReset
   * - wButtonLike
   * - wToolbarSearch
   * - wCardsCommentsLabels
   * - wCardsLikeLabels
   */
  disabledComponents: [],

  /**
   * @type {Function}
   * Array function who returns drawer navigation
   */
  drawerNavigation: () => [
    {
      type: 'link',
      icon: 'mdi-cog-outline',
      value: '/settings',
      title: (ctx) => ctx.$t('glob_sidebar_settings'),
    },
    {
      icon: 'mdi-information-outline',
      value: 'info',
      title: (ctx) => ctx.$t('glob_sidebar_moreabout'),
      noAction: true,
      disableAutoClose: true,
      items: [
        {
          type: 'link',
          title: (ctx) => ctx.$t(`glob_sidebar_moreabout_legals`),
          value: '/data/legal-notice',
        },
        {
          type: 'link',
          title: (ctx) => ctx.$t(`glob_sidebar_moreabout_terms`),
          value: '/data/terms-of-service',
        },
        {
          type: 'link',
          title: (ctx) => ctx.$t(`glob_sidebar_moreabout_data`),
          value: '/data/personal-data',
        },
        {
          type: 'method',
          title: (ctx) => ctx.$t(`glob_sidebar_moreabout_versions`),
          value: 'versions',
          onClick: (context) => {
            context.dialogVersions = true
          },
        },
      ],
    },
    {
      type: 'link',
      icon: 'mdi-help-circle-outline',
      title: (ctx) => ctx.$t(`glob_sidebar_support`),
      value: '/support',
    },
    {
      condition(context) {
        return context.$spoke.site.$data('optAuthentication') === true
      },
      type: 'method',
      icon: 'mdi-exit-to-app',
      value: 'logout',
      title: (ctx) => ctx.$t('glob_sidebar_exit'),
      onClick: async (context) => {
        await context.$store.dispatch('auth/logout')
      },
    },
  ],

  /**
   * @type {string}
   * @description you can choose, dynamically, which peristent player
   * is loaded. If you wanna create your own persistent player component,
   * change this path.
   * Not, path MUST be absolute
   * @example '@/excentrics/shells/my-shell/components/MyPlayerPersistent.vue'
   */
  dynamicLoadedPersistentPlayer: () =>
    require('~/components/WPlayerPersistent/element.vue'),

  /**
   * @type {Function}
   * @description
   * a way to display (or not) a filter in the
   * filters component
   */
  filterVisibleFilters({ page, locale }) {
    return (filter) => true
  },

  /**
   * @type {Array}
   * @description
   * List of obsolete browsers. The user who tries to access the page from one of these browsers
   * will be redirected to an error page. You have the possibility to add items to this default list.
   * Please note IE, IEmobile are currently blacklisted (you cannot authorize these browsers)
   * Find current definition in: plugins/browser-detection.js (DEFAULT_EXPLICIT_FORBIDDEN_BROWSERS_VERSIONS)
   *
   */
  forbiddenBrowsers: [],

  /**
   * @type {Object}
   * pass an object here to override ENV
   * configuration of spoke
   */
  forceSpokeConfiguration: undefined,

  /**
   * @type {Boolean}
   * if true, blocks are enabled (default true)
   */
  enableBlocks: true,

  /**
   * @type {Object}
   * if true, enable the freshdesk support service
   * otherwise, the support page will display the
   * support address (see supportEmailAddress)
   */
  enableFreshdeskSupport: false,

  /**
   * @type {Boolean}
   * @type {String} 'classic' or 'wavy'
   * @type {Object}
   * enable splashscreen compsable component
   * (customizable in ~/blocks/splashscreen.vue)
   * you can configure minTimer and maxTimer if
   * you set an object as (milliseconds):
   * {
   *   mode: 'wavy',
   *   minTimer: 6000,
   *   maxTimer: 9000
   * }
   * @default mode classic
   */
  enableSplashScreen: false,

  /**
   * @type {object}
   * Sometimes, some displayed properties may be different from those commonly accepted
   * (for example, we consider that the full name of the user, displayed in the snackbar,
   * is located in metadatas.name).
   * Here you have the possibility to set other properties.
   * For example, if you want the displayed user name to come from the `protected.firstname` property,
   * you can enter this value here in the `user.name` property.
   */
  models: {
    user: {
      name: 'metadatas.name',
    },
  },

  /**
   * @type {Number}
   * @default 9
   * limit api queries to the N firsts results from the current offset
   * (automatically computed via spoke)
   */
  paginationLimit: 9,

  /**
   * @type {String}
   * @default false
   * will redirect user homepage to `mode`
   */
  redirectHomepageToMode: false,

  /**
   * @type {Boolean}
   * @type {String}
   * @default default redirect homepage(default) to content
   * if current page has one result
   * will redirect user homepage to the given content
   * you can pass page slug or true (everywhere) false (never)
   */
  redirectHomepageToContent: 'default',

  /**
   * @type {String | Function}
   * @default '/'
   * will redirect user to the given path (default is root)
   */
  redirectSearchTo: '/',

  /**
   * @type {Boolean}
   * @default false
   * will display the content summary in contents list (wvoicy list)
   */
  showCardDescription: false,
  /**
   * @type {String}
   * @type {Array[name, options]}
   * @default null
   * If defined, will override default theme (here called shell)
   * use array notation to override default theme properties
   * (see shell readme)
   */
  useShell: undefined,

  /**
   * @type {Boolean}
   * @default true
   * From RC9: this value must be set to true to prevent some bugs related to the api rest and realtime
   * some implementations required by rc9 make it impossible for versions prior to broccoli 2.2-lts.
   * set this value to false at your own risk or for debugging the api 2.1.
   */
  useLTS: true,

  /**
   * @type {Object}
   * @description
   * will override the default `nuxt.config.js` configurations
   * @doc https://nuxtjs.org/guide/configuration
   */
  nuxt: {
    /**
     * markdown init module configuration
     * https://github.com/nuxt-community/modules/tree/master/packages/markdownit
     */
    markdownit: {
      preset: 'default',
      injected: true,
      html: true,
      linkify: true,
      breaks: true,
      typographer: true,
      use: [
        '@toycode/markdown-it-class',
        'markdown-it-container',
        'markdown-it-attrs',
      ],
    },
    /*
     ** vuetify module configuration
     ** https://github.com/nuxt-community/vuetify-module
     */
    vuetify: {
      iconfont: 'mdi',
      materialIcons: false,
      threeShake: true,
      defaultAssets: {
        font: false,
        icons: 'mdiSvg',
      },
      theme: {
        primary: '#e84b23',
        accent: '#ededed',
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },

  /**
   * @type {Function}
   * @description
   * Apply some route directive to the `$route.push` (see vue-router)
   * method called when an user set a tag on filters
   * @returns {Object}
   * @see (store:filters) #saveDateQuery
   * @see (store:filters) #saveTagQuery
   */
  overrideRouteDestFilters: (context) => {
    return {}
  },

  /**
   * @type {Object}
   * @todo document and example
   */
  routes: {
    publicRoutes: [
      'login',
      'login-redirect',
      'login-register',
      'data-id',
      /password_reset-?/,
      'register',
      'register-activate',
      'settings',
      'support',
    ],
    store: {
      'contents/getContents': ['index'],
      'contents/getContent': ['listen-content'],
      'filters/getCategories': ['index, universe'],
    },
  },

  /**
   * @type {Object}
   */
  theme: {
    /**
     * @type {String}
     * @type {Array}
     * Add excentrics assets styles files
     * Support string or array of paths
     * Eg:
     * ["~/excentrics/assets/myFont.styl"]
     */
    additionalStyles: [],
    /**
     * @type {String}
     * Use the given sprite library to generate
     * an user avatar (visible on discussions)
     * Availables :
     * - @dicebear/avatars-jdenticon-sprites
     * - @dicebear/avatars-identicon-sprites
     * - @dicebear/avatars-bottts-sprites
     * - @dicebear/avatars-gridy-sprites
     * - @dicebear/avatars-human-sprites
     * See: https://avatars.dicebear.com/ for the complete sprites
     * list.
     * Pass `false` to disable avatars rendering.
     */
    avatars: () => {
      return require('@dicebear/avatars-jdenticon-sprites').default
    },
    /**
     * @type {Object}
     * See https://google-fonts.nuxtjs.org/options
     */
    fonts: {
      families: {
        Lato: [300, 400, 500, 700],
        Rubik: [300, 400, 500],
      },
      download: true,
      outputDir: 'static/fonts/google',
      fontsPath: '~static/fonts/google/fonts',
    },
    /**
     * @type {function}
     * A function with the app context as argument
     * You must return an object with two properties :
     * - mobile {string}
     * - desktop {string}
     * One is applied depending context.isMobile boolean
     * on the highlighted component
     */
    imageGradient: (context) => {
      return {
        mobile:
          context.app.$voicer.isDarkTheme === true
            ? `to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.75) 40%, rgba(0,0,0,1) 100%`
            : `180deg, rgba(33,33,33,1) 0%, rgba(33,33,33,0.6488970588235294) 17.5%, rgba(33,33,33,0.17270658263305327) 25%, rgba(33,33,33,0) 68%, rgba(33,33,33,1) 100%`,
        desktop:
          context.app.$voicer.isDarkTheme === true
            ? `to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.75) 60%, rgba(0,0,0,1) 100%`
            : `180deg, rgba(33,33,33,1) 0%, rgba(33,33,33,0.6488970588235294) 17.5%, rgba(33,33,33,0.17270658263305327) 25%, rgba(33,33,33,0) 68%, rgba(33,33,33,1) 100%`,
      }
    },
    /**
     * @type {Object}
     * A property can be an object (style), a value (number) or a function
     * where the first argument is the context
     * @examples
     * height: (context) => context.isMobile ? 54 : 64
     * height: 54
     *
     * @notes
     * device properties are availables over device module : https://github.com/nuxt-community/device-module
     */
    components: {
      aside: {
        style: {},
      },
      card: {
        aspectRatio: 1.778,
        style: {},
      },
      player: {
        backgroundColor: (context) => {
          return context.isDarkTheme === true ? `#212121` : `#f6f6f6`
        },
      },
      toolbar: {
        color: undefined,
        dark: undefined,
        light: undefined,
        transparentPrimaryBg: false,
        transparencyBg: 0.33,
        height: (context) => {
          return context.isMobile ? 54 : 64
        },
        props: {
          flat: true,
        },
        style(context) {
          const transparencyBg = context.$voicer.getConfig(
            'theme.components.toolbar.transparencyBg',
            0.33
          )

          if (
            context.$voicer.getConfig(
              'theme.components.toolbar.transparentPrimaryBg',
              false
            ) === true
          ) {
            const rgb = hexToRgb(context.$metadatas.colorPrimary)

            return {
              backgroundColor: `rgba(${rgb.r},${rgb.g},${rgb.b},${transparencyBg})`,
            }
          }

          return context.$voicer.isDarkTheme
            ? { backgroundColor: `rgba(0, 0, 0, ${transparencyBg})` }
            : { backgroundColor: `rgba(255, 255, 255, ${transparencyBg})` }
        },
      },
      'toolbar-button': {
        colorWhenActive: 'primary',
        colorWhenInactive: undefined,
        style: (context) => (isActive) => ({}),
      },
      'toolbar-items': {
        style: {},
      },
      'toolbar-logo': {
        props: {
          width: 36,
        },
        style: {},
      },
      'toolbar-title': {
        style: {},
      },
    },
    dark: false,
  },
  // Add feedback(s) to bbc
  // WFeedBack: {
  //   // ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
  //   firstFeedback: {
  //     isRecorder: true,
  //     maxDuration: 150,
  //     headCaption: `Laissez votre dédicace ! Elle sera diffusée à l'antenne demain entre 12h et 13h`,
  //     pseudonym: {
  //       label: 'Nom ou pseudo',
  //       placeholder: 'Martin du BK de Lille',
  //     },
  //     recorderForm: [
  //       {
  //         label: 'Adresse mail',
  //         placeholder: 'martin@bk.fr',
  //         value: '',
  //         type: 'email',
  //         required: true,
  //       },
  //       {
  //         label: 'Pays',
  //         placeholder: 'France',
  //         value: '',
  //         type: 'text-field',
  //         required: false,
  //       },
  //       {
  //         label: 'Commentaire',
  //         placeholder: 'Une anecdote de travail ?',
  //         value: '',
  //         type: 'text-area',
  //         required: false,
  //       },
  //       {
  //         label: 'Numéro de téléphone',
  //         placeholder: '01 23 45 67 89',
  //         value: '',
  //         type: 'phone-number',
  //         required: false,
  //       },
  //       {
  //         label: 'Fruit préféré',
  //         items: ['Fraise', 'Banane', 'Pêche', 'Poire', 'Ananas'],
  //         value: '',
  //         type: 'select',
  //         required: true,
  //       },
  //       {
  //         label: 'Pour ou contre',
  //         value: false, // besoin d'une valeur par défault (true/false)
  //         type: 'switch',
  //       },
  //       {
  //         label: 'Accepter quelque chose',
  //         value: false, // besoin d'une valeur par défault (true/false)
  //         type: 'checkbox',
  //         required: true,
  //       },
  //     ],
  //   },
  // },
}

module.exports = config
