<template>
  <v-form ref="form" v-model="valid">
    <div v-for="field in form" :key="field.label" class="pb-2">
      <v-text-field
        v-if="
          field.type === 'text-field' ||
          field.type === 'email' ||
          field.type === 'phone-number'
        "
        v-model="field.value"
        :label="getType(field).label"
        :placeholder="field.placeholder"
        :rules="getType(field).rules"
        :mask="getType(field).mask"
        hide-details
        outline
      ></v-text-field>
      <v-switch
        v-if="field.type === 'switch'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        color="primary"
        hide-details
      ></v-switch>
      <v-checkbox
        v-if="field.type === 'checkbox'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        :rules="[field.required ? rules.required : []]"
        color="primary"
        hide-details
      ></v-checkbox>
      <v-textarea
        v-if="field.type === 'text-area'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        :placeholder="field.placeholder"
        :rules="[field.required ? rules.required : []]"
        hide-details
        outline
      ></v-textarea>
      <v-select
        v-if="field.type === 'select'"
        v-model="field.value"
        :label="field.required ? `${field.label} *` : field.label"
        :items="field.items"
        :rules="[field.required ? rules.required : []]"
        hide-details
        outline
        clearable
      ></v-select>
    </div>
  </v-form>
</template>

<script>
import spoke from '~/utils/spoke'

export default {
  props: {
    form: {
      type: Array,
      default: () => {
        return []
      },
    },
    file: {
      type: FormData,
      default: () => {
        return {}
      },
    },
    pseudonym: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  data() {
    return {
      valid: false,
      rules: {
        required: (value) => {
          return !!value || 'Champs requis.'
        },
        emailRules: (value) => {
          const pattern = /^(|\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+\s?)$/
          return pattern.test(value) || 'E-mail valide requis'
        },
        phoneRules: (value) => {
          const pattern = /^(|(0)[1-9](\d{2}){4})$/g
          return pattern.test(value) || 'Numéro de téléphone valide requis'
        },
      },
      phoneMask: '## ## ## ## ##',
    }
  },
  methods: {
    getType(field) {
      const tmpField = {
        label: field.label,
        rules: [],
      }
      if (field.required) {
        tmpField.rules = tmpField.rules.concat([this.rules.required])
        tmpField.label = `${field.label} *`
      }
      if (field.type === 'phone-number') {
        tmpField.rules = tmpField.rules.concat([this.rules.phoneRules])
        tmpField.mask = this.phoneMask
      }
      if (field.type === 'email') {
        tmpField.rules = tmpField.rules.concat([this.rules.emailRules])
      }
      return tmpField
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        const metadatas = {}
        for (let i = 0; i < this.form.length; i++) {
          const tmpLabel = this.form[i].label
          metadatas[tmpLabel] = this.form[i].value
        }

        const pseudonym = this.pseudonym
        let feedbackID = ''
        spoke.http
          .post(`/feedback`, {
            pseudonym,
            metadatas,
          })
          .then((response) => {
            feedbackID = response.item.id
            spoke.http.post(`/feedback/${feedbackID}/media`, this.file)
          })
        this.$emit('valid', true)
      } else {
        this.$emit('valid', false)
      }
    },
  },
}
</script>

<style lang="scss"></style>
